import Attachment from '@/components/fileUploading/attachment';
import Form from '@/components/form';
import FormSwitch from '@/components/form/fields/switch';
import FormTextField from '@/components/form/fields/textField';
import { ModalFormWrapper } from '@/components/form/modal';
import PageLinkComponent from '@/components/page/linkComponent';
import { mutateGraphQL } from '@/data/apollo';
import { CommerceWrite } from '@/data/commerce/commerce.graphql';
import uploadFile from '@/data/uploadFile';
import { CompanyTagsType } from '@/modals/companyTags';
import { companyOrderTagsColors } from '@/pages/dashboard/commerce/chips/deliveryStatusChip';
import usePermissions from '@/providers/auth/usePermissions';
import { theme } from '@/providers/theme';
import { MutationCommerceWriteArgs, Order } from '@/types/schema';
import { CheckCircle as CheckCircleIcon, SettingsSuggest as SettingsSuggestIcon } from '@mui/icons-material';
import { Box, Button, ListItem, ListItemButton, ListItemText, Stack, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { isEmpty, startCase, toLower } from 'lodash-es';
import { useSnackbar } from 'notistack';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import SignaturePad from 'react-signature-pad-wrapper';

export default function SignAndTagModal( { data }: { data: Order } ) {
	const signRef = useRef<SignaturePad>( null );
	const queryClient = useQueryClient();
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();
	const isOwner = usePermissions( [ 'OWNER' ] );
	const isAdmin = usePermissions( [ 'ADMIN' ] );
	const colors = companyOrderTagsColors( theme.palette.mode === 'dark' );
	const orderTags: CompanyTagsType[] = data.company?.tags?.orderTags?.filter( ( tag ) => tag?.forOrder );
	
	return (
		<Form
			initialValues={{
				status      : data.deliveryStatus || null,
				hasSignature: Boolean( data.metadata?.signature ) || false,
				sendText    : false,
				note        : data.deliveryStatusNote || '',
			}}
			onSubmit={async ( values ) => {
				let signature = data.metadata?.signature;
				if ( signRef.current?.isEmpty() === false ) {
					const blob = await new Promise<Blob>( ( resolve ) => signRef.current.canvas.current.toBlob( resolve ) );
					signature = await uploadFile( blob );
				}
				if ( signRef.current?.isEmpty() ) signature = null;
				
				await mutateGraphQL<MutationCommerceWriteArgs>( {
					mutation : CommerceWrite,
					variables: {
						id    : data.id,
						method: 'Signed and Tagged',
						input : {
							deliveryStatus    : values.status,
							deliveryStatusNote: values.note,
							metadata          : { ...data.metadata, signature },
						},
					},
				} );
				
				await queryClient.invalidateQueries( [ 'order' ] );
				await queryClient.invalidateQueries( [ 'driverOrders' ] );
				
				if ( values.status && values.sendText && data.client?.cell ) {
					await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/message`, {
						company: data.company.id,
						number : data.client.cell,
						message: `Hi ${data.client?.name || data.client?.contact || ''}\n`
							+ `Your ${toLower( data.type )} ${data.metadata?.customNumber || data.number || data.externalId}  is ${values.status}.`,
					} );
					
					enqueueSnackbar( t( 'common:text-sent' ), { variant: 'success' } );
				}
				
			}}>
			{( formik ) => (
				<ModalFormWrapper
					title={`${startCase( toLower( data.type ) )}# ${data.metadata?.customNumber || data.number}`}
					secondaryTitle='Tag and Sign'>
					<Stack spacing={2}>
						{( isOwner || isAdmin ) && (
							<Button
								startIcon={<SettingsSuggestIcon/>}
								component={PageLinkComponent}
								href='/dashboard/settings/doc?tab=general'
								sx={{ alignSelf: 'end' }}
								target='_blank'>
								{t( 'settings:create-or-edit-tags' )}
							</Button>
						)}
						{!isEmpty( orderTags ) ? orderTags.map( ( tag, index ) => (
							<ListItemButton
								key={index}
								sx={{
									'borderRadius': 2,
									// 'bgcolor'     : colors[ tag.color ].bgcolor,
									'opacity'    : tag.name !== formik.values.status ? '.6' : '1',
									'transition' : '.3s',
									'border'     : 2,
									':hover'     : {
										bgcolor: 'transparent',
									},
									'borderColor': colors[ tag.color ].textColor,
									// 'p, span'    : {
									// 	color: 'text.secondary',
									// },
								}}
								onClick={() => formik.setFieldValue( 'status', tag.name === formik.values.status
									? null
									: tag.name )}>
								<ListItemText
									primary={tag.name}
									primaryTypographyProps={{
										fontWeight: 'bold',
										variant   : 'h3',
										color     : colors[ tag.color ].textColor,
									}}
									secondary={tag?.description}
									secondaryTypographyProps={{
										color: colors[ tag.color ].textColor + ' !important',
									}}
								/>
								{tag.name === formik.values.status && <CheckCircleIcon color='success'/>}
							</ListItemButton>
						) ) : null}
						
						{formik.values.status && data?.client?.cell && (
							<ListItem disablePadding>
								<ListItemText primary={`Send text message to ${data.client.cell}`}/>
								<FormSwitch name='sendText'/>
							</ListItem>
						)}
						<FormTextField
							fullWidth
							multiline
							name='note'
							label={t( 'common:note' )}
							helperText='Add a note for the tag'
							minRows={2}
							maxRows={10}
						/>
						{formik.values.hasSignature ? (
							<Attachment
								removeDownload
								src={data.metadata.signature}
								// sx={{ justifyContent: 'center' }}
								imageSX={{ width: '100%', height: 'unset', objectFit: 'cover', bgcolor: '#ffffff' }}
							/>
						) : (
							<Box sx={{ p: 2, borderRadius: 2, bgcolor: '#ffffff', width: '100%', height: 200 }}>
								<Typography
									sx={{
										textAlign: 'center',
										color    : '#000000',
									}}>{t( 'common:sign-here' )}
								</Typography>
								<SignaturePad ref={signRef} options={{ penColor: '#000000' }}/>
							</Box>
						)}
						<Button
							variant='outlined'
							onClick={() => {
								if ( formik.values.hasSignature ) {
									formik.setFieldValue( 'hasSignature', false );
									signRef.current?.clear();
									
								} else {
									signRef.current?.clear();
								}
							}}>
							{formik.values.hasSignature ? t( 'common:redo-signature' ) : t( 'common:clear-signature' )}
						</Button>
						{formik.values.hasSignature && (
							<Button
								variant='text'
								color='error'
								onClick={() => {
									formik.setFieldValue( 'hasSignature', false );
									signRef.current?.clear();
								}}>
								{t( 'common:delete-signature' )}
							</Button>
						)}
					
					</Stack>
				</ModalFormWrapper>
			)}
		</Form>
	);
}
