import { mutateGraphQL, queryGraphQL } from '@/data/apollo';
import { PurchaseWrite } from '@/data/commerce/purchase.graphql';
import { ItemWrite } from '@/data/management/item.graphql';
import { LineItemsRead } from '@/data/management/lineItem.graphql';
import { UomsRead } from '@/data/management/uom.graphql';
import idPick from '@/helpers/idPick';
import { getNextCustomNumber } from '@/pages/dashboard/commerce/components/tableHelpers';
import {
	Item,
	ItemValidator,
	LineItemValidator,
	Location,
	Maybe,
	MutationItemsWriteArgs,
	type MutationItemWriteArgs,
	MutationPurchaseWriteArgs,
	Purchase,
	QueryLineItemsReadArgs,
	QueryUomsReadArgs,
	SimpleLineItem,
	Staff,
	Uom,
} from '@/types/schema';
import { gql } from '@apollo/client';
import { partition } from 'lodash';
import { isEmpty, isNumber, pick, uniq } from 'lodash-es';
import { v4 as uuidv4 } from 'uuid';

export const createItemsFromLineItem = async (
	lineItems: SimpleLineItem[],
	location: Maybe<Location> | undefined,
	staff: Staff | undefined ): Promise<Record<string, { uom: Uom, newItem: Item }>> => {
	
	const [ lineItemsWithSku, lineItemsWithoutSku ] = partition( lineItems, ( lineItem ) => lineItem.sku );
	const newItems: Record<string, { uom: Uom, newItem: Item }> = {};
	if ( !isEmpty( lineItemsWithSku ) ) {
		
		const lineItemUomMap = lineItemsWithSku.reduce( ( acc, lineItem ) => {
			acc[ lineItem.sku?.trim() || '' ] = lineItem;
			return acc;
		}, {} );
		
		const { uomsRead } = await queryGraphQL<QueryUomsReadArgs>( {
			query    : UomsRead,
			variables: {
				options: {
					limit : lineItemsWithSku.length,
					filter: {
						sku : { $in: uniq( lineItemsWithSku.map( ( li ) => li.sku?.trim() ) ) },
						item: { company: staff?.company?.id },
					},
				},
			},
		} );
		if ( uomsRead?.items?.length ) {
			uomsRead.items.forEach( ( uom ) => {
				const lineItem = lineItemUomMap[ uom.sku ];
				newItems[ lineItem.id ] = { uom, newItem: uom.item };
			} );
		}
	}
	
	const lineItemsWithoutUom = lineItemsWithSku.filter( ( li ) => !newItems[ li.id ] );
	const lineItemsToCreate = [ ...lineItemsWithoutUom, ...lineItemsWithoutSku ];
	
	if ( !isEmpty( lineItemsToCreate ) ) {
		const inputs: ItemValidator[] = lineItemsToCreate.map( ( lineItem ) => ( {
			id: uuidv4(),
			...pick( lineItem, [
				'name',
				'description',
			] ),
			taxable   : true,
			image     : lineItem.image || null,
			categories: lineItem.category?.id ? [ lineItem.category.id ] : [],
			locations : location ? [ location?.id ] : [],
			uoms      : [ {
				id      : uuidv4(),
				name    : lineItem.uom?.name || 'Unit',
				cost    : lineItem?.cost ? +lineItem?.cost : 0,
				price   : +lineItem.price || 0,
				markup  : lineItem.markup,
				sku     : lineItem.sku || undefined,
				sequence: 5,
				code    : lineItem.code,
				selected: true,
			} ],
		} ) );
		
		if ( inputs.length ) {
			await mutateGraphQL<MutationItemsWriteArgs>( {
				mutation : gql`mutation ItemsBatchWrite_26f2($inputs: [ItemValidator!]!, $ids:   [String!]) {
					itemsBatchWrite(inputs: $inputs, ids: $ids) {
						id
					}
				}`,
				variables: {
					inputs: inputs,
				},
			} );
		}
		
		inputs.forEach( ( item, index ) => {
			newItems[ lineItemsToCreate[ index ].id ] = { uom: item?.uoms?.[ 0 ] as any, newItem: item as any };
		} );
	}
	
	return newItems;
};

export const createItemFromLineItem = async (
	purchase: Purchase,
	lineItem: SimpleLineItem,
	location: Location | undefined,
	staff: Staff ): Promise<{ uom: Uom, newItem: Item }> => {
	const customSku = staff?.company?.metadata?.customSKU || '';
	const nextCustomSku = getNextCustomNumber( customSku );
	let uom: Uom | null = null;
	if ( lineItem.sku ) {
		const { uomsRead } = await queryGraphQL<QueryUomsReadArgs>( {
			query    : UomsRead,
			variables: { options: { limit: 1, filter: { sku: lineItem.sku, item: { company: staff.company.id } } } },
		} );
		uom = uomsRead?.items[ 0 ];
	}
	if ( uom ) return { uom: uom, newItem: uom.item };
	const { itemWrite } = await mutateGraphQL<MutationItemWriteArgs>( {
		mutation : ItemWrite,
		variables: {
			method   : 'Item from Line Item',
			customSKU: Boolean( nextCustomSku ), // for item write, invalidate user query to update company customSKU
			input    : {
				...pick( lineItem, [
					'name',
					'description',
				] ),
				taxable   : true,
				image     : lineItem.image,
				categories: lineItem.category?.id ? [ lineItem.category.id ] : [],
				locations : purchase.companyLocation?.id || location
					? [ purchase.companyLocation?.id || location?.id ] as string[]
					: undefined,
				uoms      : [ {
					name    : lineItem.uom?.name || 'Unit',
					cost    : +lineItem?.cost || 0,
					price   : +lineItem.price || 0,
					markup  : lineItem.markup,
					sku     : nextCustomSku || lineItem.sku || undefined,
					selected: true,
					code    : lineItem.code,
				} ],
			},
		},
	} );
	return { uom: itemWrite.uoms[ 0 ], newItem: itemWrite };
};

export const createItemForPurchase = async (
	formik: any,
	lineItem: SimpleLineItem,
	location: Location,
	staff: Staff,
	queryClient: any ) => {
	let lineItems: LineItemValidator[];
	const { uom, newItem } = await createItemFromLineItem( formik.values, lineItem, location, staff );
	
	// create new item if uom not found
	if ( !uom ) {
		await queryClient.invalidateQueries( [ 'user' ] );
		lineItems = formik.values.lineItems?.map( ( li ) => {
			const updatedLineItem = li.id === lineItem.id;
			const newUom = updatedLineItem ? newItem?.uoms?.[ 0 ] : null;
			
			return {
				...idPick( li, [
					'name',
				] ),
				stocked: updatedLineItem ? false : undefined,
				price  : newUom?.price || li.price,
				cost   : newUom?.cost || li.cost,
				sku    : newUom?.sku || li.sku,
				unit   : newUom?.name || li.unit,
				uom    : newUom?.id || null,
				item   : updatedLineItem ? newItem?.id : li.item?.id || null,
			};
		} );
	} else {
		lineItems = formik.values.lineItems?.map( ( li ) => {
			const updatedLineItem = li.id === lineItem.id;
			const oldUom = updatedLineItem ? uom : null;
			const oldItem = updatedLineItem ? uom?.item : null;
			
			return {
				...idPick( li, [
					'name',
				] ),
				stocked: updatedLineItem ? false : undefined,
				uom    : oldUom?.id || null,
				item   : oldItem?.id || li.item?.id || null,
			};
		} );
	}
	
	const { purchaseWrite } = await mutateGraphQL<MutationPurchaseWriteArgs>( {
		mutation : PurchaseWrite,
		variables: {
			id   : formik.values.id,
			input: {
				lineItems,
			},
		},
	} );
	
	formik.setValues( purchaseWrite );
	const { lineItemsRead } = await queryGraphQL<QueryLineItemsReadArgs>( {
		query    : LineItemsRead,
		variables: { options: { limit: 1000, filter: { purchase: purchaseWrite.id } } },
	} );
	
	const purchaseLineItems = lineItemsRead.items || [];
	formik.setFieldValue( 'lineItems', purchaseLineItems.map( ( lineItem ) => ( {
		...lineItem,
		receivedQuantity: isNumber( lineItem.receivedQuantity )
			? lineItem.receivedQuantity : lineItem.quantity,
	} ) ) );
};
