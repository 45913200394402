import type { TableBodyProps, TableProps, TableRowProps } from '@mui/material';
import { Box, Table, TableBody, TableRow } from '@mui/material';
import type { ReactNode } from 'react';

export default function TableWrapper( {
	children,
	rowProps,
	bodyProps,
	tableProps,
}: {
	children?: ReactNode,
	rowProps?: TableRowProps,
	bodyProps?: TableBodyProps,
	tableProps?: TableProps
} ) {
	return (
		<Box sx={{ '.MuiTableCell-root': { border: 0, py: .5, px: 0 } }}>
			<Table {...tableProps}>
				<TableBody {...bodyProps}>
					<TableRow {...rowProps} sx={{ 'display': 'flex', 'td:first-of-type': { alignSelf: 'start' } }}>
						{children}
					</TableRow>
				</TableBody>
			</Table>
		</Box>
	);
}
