import SignAndTagModal from '@/pages/dashboard/commerce/components/signAndTagModal';
import { useModal } from '@/providers/modal';
import { Order } from '@/types/schema';
import { Chip, Tooltip, useTheme } from '@mui/material';

export function companyOrderTagsColors( darkMode: boolean ) {
	return {
		Blue  : {
			bgcolor  : darkMode ? '#4BA3D2' : '#B3D9FF',
			textColor: darkMode ? '#E0F7FA' : '#1565C0',
		},
		Orange: {
			bgcolor  : darkMode ? '#cf8922' : '#FFD699',
			textColor: darkMode ? '#FFE0B2' : '#E65100',
		},
		Purple: {
			bgcolor  : darkMode ? '#7575CF' : '#D1D1FF',
			textColor: darkMode ? '#EDE7F6' : '#7859ff',
		},
		Green : {
			bgcolor  : darkMode ? '#37A337' : '#A6EBA6',
			textColor: darkMode ? '#E8F5E9' : '#218829',
		},
		Red   : {
			bgcolor  : darkMode ? '#FF3333' : '#FF9999',
			textColor: darkMode ? '#FFCDD2' : '#B71C1C',
		},
	};
}

export default function DeliveryStatusChip( {
	tooltip,
	order,
	disableOnClick,
}: {
	tooltip?: string,
	order: Order,
	disableOnClick?: boolean
} ) {
	const { showModal } = useModal();
	const theme = useTheme();
	const colors = companyOrderTagsColors( theme.palette.mode === 'dark' );
	const orderTagColor = order?.company?.tags?.orderTags?.find( ( tag ) => tag.name === order.deliveryStatus )?.color;
	
	const content = (
		<Chip
			variant='alpha'
			color='info'
			sx={order?.deliveryStatus ? {
				'bgcolor': `${colors[ orderTagColor ]?.bgcolor} !important`,
				'color'  : `${colors[ orderTagColor ]?.textColor} !important`,
				'&:hover': {
					transition: 'all 0.3s',
					filter    : !disableOnClick && 'brightness(.9)',
				},
			} : {
				'&:hover': {
					transition: 'all 0.3s',
					filter    : !disableOnClick && 'brightness(1.1)',
				},
			}}
			label={order.deliveryStatus || 'Tag'}
			onClick={!disableOnClick ? ( e ) => {
				e.stopPropagation();
				showModal( SignAndTagModal, { maxWidth: 'sm' }, { data: order } );
			} : undefined}
		/>
	);
	
	if ( tooltip ) {
		return (
			<Tooltip title={tooltip} placement='right'>
				{content}
			</Tooltip>
		);
	}
	return content;
}

