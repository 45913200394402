import { mutateGraphQL, queryGraphQL } from '@/data/apollo';
import { CommercesDeleteMergedTo } from '@/data/commerce/commerce.graphql';
import { InvoiceRead, InvoiceWrite } from '@/data/commerce/invoice.graphql';
import {
	MutationCommercesDeleteMergedToArgs,
	MutationInvoiceWriteArgs,
	Order,
	QueryInvoiceReadArgs,
	Staff,
} from '@/types/schema';
import { gql } from '@apollo/client';
import { addDays } from 'date-fns';
import { isEmpty, omit, pick } from 'lodash-es';

export const encodeFilters = ( filters: { filters: any } ) => encodeURIComponent( btoa( JSON.stringify( filters ) ) );

export const commercePropertiesToOmit = [
	'cardFee',
	'portionCardFee',
	'cashDiscount',
	'cardInfo',
	'mergedTo',
	'mergedFrom',
	'qbSyncToken',
	'qbClientId',
	'privateNote',
	'stock',
	'partiallyStocked',
	'paymentByInfo',
	'paymentSignatures',
	'originalCommerce',
	'paymentNote',
	'sentDates',
	'mergedSignatures',
	'reminderDates',
	'hideOnClover',
	'state',
	'customNumber',
];

export const lineItemPropertiesToOmit = [
	'stock',
	'purchaseReceived',
];

export async function makeInvoiceCopy( invoice: Order, staff?: Staff ) {
	const { invoiceRead } = await queryGraphQL<QueryInvoiceReadArgs>( {
		query    : InvoiceRead,
		variables: { id: invoice.id },
	} );
	
	const lineItems = invoiceRead.lineItems;
	const agreements = invoiceRead.agreements;
	const prices = invoiceRead.prices;
	
	const newLineItems: any[] = lineItems.map( ( lineItem, index ) => ( {
		name          : lineItem.name,
		image         : lineItem.image,
		unit          : lineItem.unit || null,
		description   : lineItem.description,
		quantity      : +lineItem.quantity || 1,
		cost          : +lineItem.cost || 0,
		price         : +lineItem.price || 0,
		originalPrice : +lineItem.originalPrice,
		cashDiscount  : +lineItem.cashDiscount,
		tax           : lineItem.tax,
		metadata      : omit( lineItem.metadata, lineItemPropertiesToOmit ),
		sequence      : index,
		code          : lineItem.code,
		orderTax      : lineItem.orderTax,
		uom           : lineItem.uom?.id || null,
		item          : lineItem.item?.id ?? undefined,
		category      : lineItem.category?.id ?? undefined,
		modifierGroups: lineItem.modifierGroups?.map( ( { id } ) => id ),
		prices        : lineItem.prices?.map( ( price ) => ( {
			...pick( price, [ 'name', 'isPercent', 'value', 'quantity', 'externalId' ] ),
			metadata: price.metadata || {},
			quantity: price.quantity !== undefined ? price.quantity : 1,
		} ) ),
	} ) );
	
	const { invoiceWrite } = await mutateGraphQL<MutationInvoiceWriteArgs>( {
		mutation : InvoiceWrite,
		variables: {
			id          : null,
			customNumber: Boolean( staff.company.metadata?.customNumber ),
			method      : 'Copied Invoice',
			input       : {
				...pick( invoiceRead, [
					'type',
					'notes',
					'po',
					'terms',
					'standing',
					'standingDue',
					'standingData',
					'duePeriod',
					'serviceType',
					'attachments',
					'dateSent',
					'taxPercent',
				] ),
				lineItems      : newLineItems,
				gateway        : null,
				standingDate   : new Date(),
				dueDate        : addDays( new Date(), invoiceRead.company?.metadata?.dueDate || invoiceRead.company?.metadata?.serviceDateDue || 0 ),
				serviceDate    : new Date(),
				staff          : staff.id || invoiceRead.staff?.id,
				client         : invoiceRead.client?.id || null,
				houseAccount   : invoiceRead.houseAccount?.id || null,
				companyLocation: invoiceRead.companyLocation?.id || null,
				shippingAddress: invoiceRead.shippingAddress?.id || null,
				clientAddress  : invoiceRead.clientAddress?.id || null,
				metadata       : {
					...omit( invoiceRead.metadata, commercePropertiesToOmit ),
					sendReminder      : staff.company.metadata?.dueReminder,
					enableCashDiscount: staff.company.metadata?.cashDiscount > 0,
					signatureLine     : staff.company.metadata?.signatureLine,
				},
				agreements     : agreements?.map( ( agreement ) => ( {
					...pick( agreement, [
						'title',
						'body',
						'requireSignature',
						'expiration',
					] ),
					company: invoiceRead.company.id,
				} ) ),
				// scheduledPayments: scheduledPayments?.map( ( payment ) => ( {
				// 	...pick( payment, [ 'percent', 'reason', 'dueDate' ] ),
				// 	staff  : invoice?.staff?.id || staff?.id,
				// 	client : invoice?.client?.id || null,
				// 	company: invoice?.company.id,
				// } ) ),
				prices: !isEmpty( prices )
					? prices.filter( ( { name } ) => name !== 'Card Processing Fee' && name !== 'Cash Discount' && name !== 'Credit' )
						.map( ( price ) =>
							pick( price, [
								'name',
								'isPercent',
								'value',
								'quantity',
								'metadata',
							] ) )
					: [],
			},
		},
	} );
	return invoiceWrite.id;
}

export const deleteMergedTo = async ( invoices: Order[] ) => {
	const accountInvoices = invoices.filter( ( invoice ) => invoice.type === 'ACCOUNT' );
	if ( !isEmpty( accountInvoices ) ) {
		await mutateGraphQL<MutationCommercesDeleteMergedToArgs>( {
			mutation : CommercesDeleteMergedTo,
			variables: {
				ids: accountInvoices.map( ( invoice ) => invoice.id ),
			},
		} );
	}
};

export const LineItemsFetch = gql`query LineItemsRead_96e7($options: FilterOptions) {
	lineItemsRead(options: $options) {
		items {
			id
			name
		}
	}
}`;
